import { useMemo } from "react";
import AsyncSelect from "react-select/async";

/** Used to avoid rendering thousands of options at once, which causes performance issues */
const RENDERED_OPTIONS_LIMIT = 100;

const SelectInput = (props) => {
	const { isMandatory, options, ...inputProps } = props;

	const defaultOptions = useMemo(() => options.slice(0, RENDERED_OPTIONS_LIMIT), [options]);

	/** Loads only a max of RENDERED_OPTIONS_LIMIT options that match inputValue whenever it changes */
	const loadOptions = (inputValue, callback) => {
		const lowerCaseInputValue = inputValue.toLowerCase();

		if (!inputValue) {
			callback(options.slice(0, RENDERED_OPTIONS_LIMIT));
		} else {
			const filteredOptions = options
				.filter(
					(option) =>
						option.value.toLowerCase().includes(lowerCaseInputValue) ||
						option.label?.toLowerCase().includes(lowerCaseInputValue) ||
						option.section?.toLowerCase().includes(lowerCaseInputValue)
				)
				.slice(0, RENDERED_OPTIONS_LIMIT);

			callback(filteredOptions);
		}
	};

	return (
		<AsyncSelect
			{...inputProps}
			backspaceRemovesValue
			defaultOptions={defaultOptions}
			loadOptions={loadOptions}
			styles={{
				menu: (baseStyles) => ({ ...baseStyles, zIndex: 3 }),
				option: (baseStyles, state) => ({
					...baseStyles,
					fontSize: 14,
					textAlign: "left",
					backgroundColor: state.isSelected ? "rgba(206, 235, 218, 1) !important" : "",
					color: state.isSelected ? "rgba(45, 94, 65, 1)" : "",
					cursor: "pointer",
					"&:hover": {
						backgroundColor: "rgba(206, 235, 218, 0.5)",
					},
				}),
				indicatorSeparator: (baseStyles) => ({
					...baseStyles,
					display: "none",
				}),
				control: (baseStyles, state) => ({
					...baseStyles,
					height: 36,
					fontSize: 14,
					borderRadius: 4,
					transition: "none",
					border:
						isMandatory && (!props.value || props.value.value === "DEFAULT")
							? "1px solid #dc3545"
							: "1px solid #D7DAE0",
					cursor: "pointer",
					outline: state.isFocused ? "2px solid #56946F" : "",
					"&:hover": {
						border: "1px solid transparent",
						outline: !state.isFocused ? "2px solid #86C49F" : "",
					},
				}),
			}}
		/>
	);
};

export default SelectInput;
