import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";
import i18n from "../i18n";

export const sendMessagesToAi = async (messages, workspace) => {
	try {
		const result = await axiosInstance.post(
			config.apiUrl + "/llm",
			{ messages, workspace },
			{
				headers: getHeaders(),
			}
		);

		return result.data.response;
	} catch (e) {
		const errorMessage = {
			role: "assistant",
			content:
				e.response.status === 403
					? i18n.t("common:aiSubscriptionLimitReached")
					: i18n.t("common:sendMessageError"),
		};

		return errorMessage;
	}
};
