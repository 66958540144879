import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../../../context/theme/ThemeContext";
import SubscriptionUsageTrackers from "./SubscriptionUsageTrackers";

const SubscriptionUsage = () => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);

	return (
		<div className={withTheme("settings-section")}>
			<div className={withTheme("settings-section-label")}>{t("subscriptionUsage")}</div>

			<div style={{ maxWidth: "50%" }}>
				<SubscriptionUsageTrackers />
			</div>
		</div>
	);
};

export default SubscriptionUsage;
