import { useContext, useEffect, useMemo, useState } from "react";
import WorkspacesContext from "../../../../context/workspaces/WorkspacesContext";
import Track from "../../../../components/layouts/Track";
import { fetchDeclarations } from "../../../../api/declarations";
import LoadingSpinner from "../../../../components/common/LoadingSpinner";
import { useTranslation } from "react-i18next";

const SubscriptionUsageTrackers = () => {
	const { workspaces, selectedWorkspaceId } = useContext(WorkspacesContext);
	const [usage, setUsage] = useState(null);
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const currentWorkspace = useMemo(
		() => workspaces.find((workspace) => workspace._id === selectedWorkspaceId),
		[workspaces, selectedWorkspaceId]
	);

	const getSubscriptionUsage = async () => {
		setIsLoading(true);
		const result = await fetchDeclarations(selectedWorkspaceId, { count: true, submitted: true });

		setUsage({
			declarations: result.count,
			aiTokens: currentWorkspace.subscription.usage.aiTokens,
		});

		setIsLoading(false);
	};

	useEffect(() => {
		if (!usage && currentWorkspace) {
			getSubscriptionUsage();
		}
	}, [currentWorkspace]);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			<Track
				label={t("totalDeclarationsSubmitted")}
				value={usage?.declarations || 0}
				total={currentWorkspace?.subscription.limits.declarationsLimit}
			/>
			<div style={{ marginTop: 32 }}>
				<Track
					label={t("aiTokensUsed")}
					value={
						Math.min(
							usage?.aiTokens,
							currentWorkspace?.subscription.limits.aiTokensLimit
						) || 0
					}
					total={currentWorkspace?.subscription.limits.aiTokensLimit}
				/>
			</div>
		</>
	);
};

export default SubscriptionUsageTrackers;
