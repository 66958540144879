import { useContext, useEffect, useRef, useState } from "react";
import ThemeContext from "../../../context/theme/ThemeContext";
import AIChatBoxHeader from "./components/Header";
import MessageInput from "./components/MessageInput";
import Message from "./components/Message";
import { trim } from "lodash";
import LoadingSpinner from "../../common/LoadingSpinner";
import { sendMessagesToAi } from "../../../api/ai-chat";
import WorkspacesContext from "../../../context/workspaces/WorkspacesContext";

const INITIAL_MESSAGES = [{ role: "assistant", content: "Hello! How can I help you today?" }];

const AIChatBox = ({ isOpen, onClose }) => {
	const { withTheme } = useContext(ThemeContext);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);

	const [messages, setMessages] = useState(INITIAL_MESSAGES);
	const [isResponseLoading, setIsResponseLoading] = useState(false);
	const messagesListRef = useRef();

	const handleMessageSend = async (message) => {
		if (!trim(message) || isResponseLoading) {
			return;
		}

		const updatedMessages = [...messages, { role: "user", content: message }];

		setMessages(updatedMessages);

		setIsResponseLoading(true);

		const response = await sendMessagesToAi(updatedMessages, selectedWorkspaceId);

		setMessages([...updatedMessages, response]);

		setIsResponseLoading(false);
	};

	useEffect(() => {
		if (messagesListRef.current) {
			messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
		}
	}, [messages]);

	if (!isOpen) {
		return null;
	}

	return (
		<div className={withTheme("chat-box")}>
			<AIChatBoxHeader onClose={onClose} />

			<div ref={messagesListRef} className={withTheme("chat-box-messages-container")}>
				{messages.map((message, index) => (
					<Message message={message} key={index} />
				))}

				{isResponseLoading && (
					<LoadingSpinner
						size="sm"
						style={{
							width: "fit-content",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					/>
				)}
			</div>

			<MessageInput onSend={handleMessageSend} />
		</div>
	);
};

export default AIChatBox;
