import validation from "../../constants/validation";

import { validateElementValue } from "../../components/common/ConstantsBasedTextInput/utils";
import itemsGrouping from "../../constants/grouping/import/items-grouping.json";
import headerGrouping from "../../constants/grouping/import/header-grouping.json";
import {
	SET_DISABLED_DECLARATION_ELEMENTS,
	SET_MANDATORY_DECLARATION_ELEMENTS,
	SET_REQUIRED_PULLDOWN_OPTIONS,
	SET_VALIDATION_ERRORS,
} from "../types";
import headerObligations from "../../constants/obligations/header-obligations";
import itemsObligations from "../../constants/obligations/items-obligations";
import headerOrItemsElements from "../../constants/grouping/import/header-or-items-elements.json";
import {
	contactSections,
	headerContactSections,
	itemContactSections,
} from "../../components/common/InputsSection/CustomSections";
import { capitalize, isArray, trim } from "lodash";
import { getQueryParam, updateQueryParam } from "../../router/utils";
import {
	ELEMENT_QUERY_PARAM,
	ITEM_QUERY_PARAM,
	PAGE_QUERY_PARAM,
	SEARCH_TYPE_ELEMENTS,
	TAB_QUERY_PARAM,
} from "../../constants/GlobalConstants";

/**
 * This validates that the element "unLocode" cannot have a value while any of
 * "deliveryTermsCountryCode" and "deliveryTermsCityCode" have values
 */
const validateUnLocodeElement = (errors, findValueInContext) => {
	const unLocodeValidationElement = validation.find((element) => element.elementName === "unLocode");
	const unLocodeValue = findValueInContext("unLocode");
	const countryCodeValue = findValueInContext("deliveryTermsCountryCode");
	const cityCodeValue = findValueInContext("deliveryTermsCityCode");

	if (unLocodeValidationElement && unLocodeValue) {
		if (countryCodeValue || cityCodeValue) {
			errors.push({
				payload: {
					elements: [unLocodeValidationElement],
				},
				message: "Element cannot have a value while **Delivery Terms Country Code** or **Delivery Terms City Code** also have values",
			});
		}
	} else if (countryCodeValue || cityCodeValue) {
		if (!countryCodeValue) {
			const countryCodeValidationElement = validation.find(
				(element) => element.elementName === "deliveryTermsCountryCode"
			);
			errors.push({
				payload: {
					elements: [countryCodeValidationElement],
				},
				message: `**${countryCodeValidationElement.label}** is required`,
			});
		} else if (!cityCodeValue) {
			const cityCodeValidationElement = validation.find(
				(element) => element.elementName === "deliveryTermsCityCode"
			);
			errors.push({
				payload: {
					elements: [cityCodeValidationElement],
				},
				message: `**${cityCodeValidationElement.label}** is required`,
			});
		}
	}
};

export const getValidationErrorsUtil = (state, dispatch, findValueInContext) => {
	let errors = [];
	const { mandatoryElements, requiredPulldownOptions, declaration } = state;

	const validateElement = (validationElement, elementValue) => {
		if (validationElement.elementName === "unLocode" && elementValue) {
			if (elementValue) {
				validateElementValue(validationElement, elementValue, (warning) => {
					if (warning) {
						errors.push({
							payload: {
								elements: [validationElement],
							},
							message: warning,
						});
					}
				});
			}
		}
	};

	validateUnLocodeElement(errors, findValueInContext);

	/**
	 * Validate the elements in all contact sections
	 * By default, all contact elements are required. However, they will not be required only if:
	 *   - Contact name (contactName) is filled
	 *   - EORI Code (contactEori) is filled
	 */

	const getSectionErrors = (section, itemIndex) => {
		let sectionObject = declaration[section];
		if (typeof itemIndex === "number") {
			if (!declaration.data.declarationItems) {
				return;
			}
			sectionObject = declaration.data.declarationItems[itemIndex][section];
		}

		if (
			!sectionObject ||
			!Object.keys(sectionObject).length ||
			(!sectionObject?.contactName && !sectionObject?.contactEori)
		) {
			if (!mandatoryElements.find((element) => element.elementName === section)) {
				return;
			}

			SEARCH_TYPE_ELEMENTS.CONTACTS.forEach((element) => {
				const validationElement = validation.find(
					(valElement) => valElement.elementName === element
				);
				let elementValue = sectionObject && sectionObject[element];
				if (validationElement && !elementValue) {
					errors.push({
						payload: {
							elements: [element],
							itemIndex,
							level: typeof itemIndex === "number" ? "item" : "header",
						},
						message: `**${capitalize(section)}** - **${validationElement.label
							.replaceAll(": ", "")
							.replaceAll(":", "")}**: Element is required`,
					});
				}
			});
		}
	};

	headerContactSections.forEach((section) => {
		getSectionErrors(section);
	});

	declaration.declarationItems?.forEach((item, index) => {
		itemContactSections.forEach((section) => {
			getSectionErrors(section, index);
		});
	});

	// Validate mandatory elements
	mandatoryElements?.forEach((element) => {
		const validationElement = validation.find((item) => item.elementName === element.elementName);
		if (contactSections.includes(element.elementName)) {
			return;
		}

		// If element is on header level, get the value directly from the declaration object in state
		if (element.level === "header") {
			const elementValue = findValueInContext(element.elementName);
			if ((!trim(elementValue) || elementValue?.length === 0) && validationElement) {
				errors.push({
					payload: {
						elements: [validationElement],
						level: "header",
					},
					message: `**${validationElement.label}** is required`,
				});
			} else if (validationElement) {
				validateElement(validationElement, elementValue);
			}
			// If element is on item level, loop through all declaration items and find the element value in each item
		} else if (element.level === "item") {
			declaration.declarationItems?.forEach((item, index) => {
				if (!trim(item[element.elementName])) {
					errors.push({
						payload: {
							elements: [validationElement],
							level: "item",
							itemIndex: index,
						},
						message: `**${validationElement.label}** is required`,
					});
				}
			});
		}
	});

	// Validate multiline elements that do not contain an item with one of the required child element keys
	Object.keys(requiredPulldownOptions).forEach((key) => {
		const { multilineElement, keys, level } = requiredPulldownOptions[key];

		const getErrorMessage = (validationPulldownElement) => {
			return `The element **${validationPulldownElement.label
				.replace(": ", "")
				.replace(":", "")}** requires at least one of the following keys: ${keys
				.map((k) => `**${k}**`)
				.join(", ")}`;
		};

		const validateElement = (elementValue, itemIndex) => {
			if (!isArray(elementValue) || !elementValue.find((item) => keys.includes(item[key]))) {
				const validationMultilineElement = validation.find(
					(item) => multilineElement === item.elementName
				);

				const validationPulldownElement = validation.find((item) => key === item.elementName);

				if (validationMultilineElement && validationPulldownElement) {
					errors.push({
						payload: {
							elements: [validationMultilineElement],
							level,
							itemIndex,
						},
						message: getErrorMessage(validationPulldownElement),
					});
				}
			}
		};

		if (level === "header") {
			let multilineElementValue = findValueInContext(multilineElement);
			console.log(multilineElement, multilineElementValue);
			validateElement(multilineElementValue);
		} else if (level === "item") {
			declaration.declarationItems?.forEach((item, index) => {
				validateElement(item[multilineElement], index);
			});
		}
	});

	const grouping = getQueryParam(PAGE_QUERY_PARAM) === 1 ? itemsGrouping : headerGrouping;

	grouping.forEach((tab) => {
		tab.sections?.forEach((section) => {
			const findElementInSection = (elements) => {
				elements?.forEach((element) => {
					const elementValue = findValueInContext(element);

					const validationElement = validation.find(
						(item) => item.elementName === element.elementName
					);

					if (
						!errors.find((err) => err.element?.elementName === element) &&
						validationElement &&
						elementValue
					) {
						validateElement(validationElement, elementValue);
					}
					if (element.type === "row") {
						findElementInSection(element.elements);
					}
				});
			};

			findElementInSection(section.elements);
		});
	});

	const headerOrItemValidationErrors = validateHeaderOrItemElementsUtil(findValueInContext);

	dispatch({
		type: SET_VALIDATION_ERRORS,
		payload: [...headerOrItemValidationErrors, ...errors],
	});

	return [...headerOrItemValidationErrors, ...errors];
};

export const findElementObligationsUtil = (state, dispatch, findValueInContext) => {
	// Initialize arrays to track different types of form elements
	let mandatoryElements = [];
	let disabledElements = [];
	let requiredPulldownOptions = {};

	// Contains both header and item level obligation rules
	// index 0 = header obligations, index 1 = item obligations
	const obligationFiles = [headerObligations, itemsObligations];

	// Process each obligation file (header and items)
	obligationFiles.forEach((obligations, index) => {
		const level = index === 0 ? "header" : "item";

		// Helper function to process individual rules and their effects
		const handleMatchedRule = (rule, level, itemIndex) => {
			// Handle mandatory fields (e.g., "fieldName.isMandatory")
			if (rule.includes(".isMandatory")) {
				mandatoryElements.push({
					elementName: rule.replace(".isMandatory", ""),
					level,
					itemIndex,
				});
			}
			// Handle disabled fields (e.g., "fieldName.isDisabled")
			else if (rule.includes(".isDisabled")) {
				disabledElements.push({
					elementName: rule.replace(".isDisabled", ""),
					level,
					itemIndex,
				});
			}
			// Handle pulldown fields with required values (e.g., "multiline.pulldown.mustHave.value1,value2")
			else if (rule.includes(".mustHave")) {
				const [multilineElement, pulldownElement, , requiredKeysStr] = rule.split(".");
				const requiredKeys = requiredKeysStr.split(",");
				requiredPulldownOptions[pulldownElement] = {
					multilineElement,
					level,
					itemIndex,
					keys: requiredKeys,
				};
			}
		};

		// Process each rule in the current obligation file
		obligations.rules.forEach((rule) => {
			let matches = false;
			let matchingItems = [];

			// Check all conditions in the rule's "if" clause
			rule.if.forEach((condition) => {
				// Handle item-level conditions
				if (level === "item") {
					state.declaration.declarationItems?.forEach((item, index) => {
						// Check if all conditions match for this item
						const conditionMatches = Object.keys(condition).every((key) =>
							condition[key].includes(findValueInContext(key) || item[key])
						);

						if (conditionMatches) {
							matchingItems.push(index);
						}
					});
				}
				// Handle header-level conditions
				else {
					// Check if all conditions match at header level
					const conditionMatches = Object.keys(condition).every((key) =>
						condition[key].includes(findValueInContext(key))
					);

					if (conditionMatches) {
						matches = true;
					}
				}
			});

			// Apply rules when conditions are met
			if (matches && level === "header") {
				rule.then.forEach((rule) => handleMatchedRule(rule, "header"));
			}

			if (level === "item") {
				matchingItems.forEach((index) => {
					rule.then.forEach((rule) => handleMatchedRule(rule, "item", index));
				});
			}
		});

		// Process group rules - if one element in a group has a value,
		// all other elements in that group become mandatory
		obligations.groups.forEach((group) => {
			let groupHasValue = false;
			group.forEach((element) => {
				if (findValueInContext(element)) {
					groupHasValue = true;
				}
			});

			if (groupHasValue) {
				mandatoryElements = [
					...mandatoryElements,
					...group.map((element) => ({ elementName: element, level })),
				];
			}
		});

		// Get service-specific obligations based on the current declaration service
		const serviceObject = obligations.services[state.declaration.service] || {};

		// Process service-specific mandatory fields
		// If a service field has a value of false, it becomes mandatory
		Object.keys(serviceObject).forEach((key) => {
			const value = serviceObject[key];

			if (!value) {
				mandatoryElements.push({ elementName: key, level });
			}
		});
	});

	// Update application state with the processed obligations
	dispatch({
		type: SET_MANDATORY_DECLARATION_ELEMENTS,
		payload: mandatoryElements,
	});

	dispatch({
		type: SET_DISABLED_DECLARATION_ELEMENTS,
		payload: disabledElements,
	});

	dispatch({
		type: SET_REQUIRED_PULLDOWN_OPTIONS,
		payload: requiredPulldownOptions,
	});
};

export const handleValidationErrorsView = (error) => {
	const { elements } = error.payload;

	const elementName = elements.find((element) => element?.elementName)?.elementName;
	if (!elementName) {
		return;
	}

	updateQueryParam(ELEMENT_QUERY_PARAM, elementName);

	if (typeof error.payload.itemIndex === "number") {
		updateQueryParam(ITEM_QUERY_PARAM, error.payload.itemIndex);
	}

	updateQueryParam(TAB_QUERY_PARAM, error.payload.level === "item" ? 1 : 0);
};

/** Header or Items elements should only have a value either on header or item level,
 * this method validates this rule and shows errors in the notifications panel if there are any
 */
export const validateHeaderOrItemElementsUtil = (findValueInContext) => {
	let errors = [];

	headerOrItemsElements.forEach((element) => {
		const isSection = element.includes("section-");
		const elementOrSectionName = isSection ? element.split("section-")[1] : element;

		const headerValue = findValueInContext(elementOrSectionName, "header");
		const itemValue = findValueInContext(elementOrSectionName, "item");

		if (headerValue && itemValue) {
			// Return if the value is of type array and one of the header or item arrays are empty
			if (Array.isArray(headerValue) && Array.isArray(itemValue)) {
				if (!headerValue.length || !itemValue.length) {
					return;
				}
			} else if (typeof headerValue === "object" && typeof itemValue === "object") {
				if (
					!Object.values(headerValue).filter((value) => value).length ||
					!Object.values(itemValue).filter((value) => value).length
				) {
					return;
				}
			}
			const validationElement = validation.find(({ elementName }) =>
				isSection
					? elementName === Object.keys(headerValue)[0]
					: elementName === elementOrSectionName
			);

			errors.push({
				message: `The ${element.startsWith("section") ? "section" : "element"} **${
					element === "buyer" ? "Contact" : ""
				}${
					!isSection
						? validationElement.label.replaceAll(":", "")
						: capitalize(elementOrSectionName)
				}** has a value on both header and item levels. It is allowed to have a value on only one of them`,
				payload: {
					level: "header",
					elements: [validationElement],
				},
			});
		}
	});

	return errors;
};
