import SideNavBar from "../navigation/SideNavBar/SideNavBar";
import MainNavBar from "../navigation/MainNavBar/MainNavBar";
import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../context/theme/ThemeContext";
import AIChatBox from "./chat-box/AIChatBox";

const PageContainer = ({ title, style, innerStyle, children }) => {
	const [isSideNavBarExpanded, setIsSideNavBarExpanded] = useState(window.innerWidth >= 1366);
	const [isAiChatBoxOpen, setIsAiChatBoxOpen] = useState(false);
	const { withTheme } = useContext(ThemeContext);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(min-width: 1366px)");

		const eventHandler = () => {
			if (window.innerWidth >= 1366) {
				setIsSideNavBarExpanded(true);
			} else {
				setIsSideNavBarExpanded(false);
			}
		};

		mediaQuery.addEventListener("change", eventHandler);

		return () => {
			mediaQuery.removeEventListener("change", eventHandler);
		};
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<SideNavBar isExpanded={isSideNavBarExpanded} setIsExpanded={setIsSideNavBarExpanded} />
			<MainNavBar
				title={title}
				isSideNavBarExpanded={isSideNavBarExpanded}
				isAiChatBoxOpen={isAiChatBoxOpen}
				setIsAiChatBoxOpen={setIsAiChatBoxOpen}
			/>

			<div
				style={style}
				className={`${withTheme("page-container")} ${
					!isSideNavBarExpanded ? withTheme("page-container-collapsed-nav") : ""
				}`}
			>
				<div style={innerStyle} className={`${withTheme("page-container-inner")}`}>
					{children}
				</div>
			</div>

			<AIChatBox isOpen={isAiChatBoxOpen} onClose={() => setIsAiChatBoxOpen(false)} />
		</div>
	);
};

export default PageContainer;
