export const userSettingElements = [
	{
		label: "Address Line 1",
		elementName: "addressLine1",
		type: "text",
	},
	{
		label: "Address Line 2",
		elementName: "addressLine2",
		type: "text",
	},
	{
		label: "Address Line 3",
		elementName: "addressLine3",
		type: "text",
	},
	{
		label: "Address Line 4",
		elementName: "addressLine4",
		type: "text",
	},
	{
		label: "Country",
		elementName: "country",
		type: "text",
	},
	{
		label: "City",
		elementName: "city",
		type: "text",
	},
	{
		label: "Postcode",
		elementName: "postcode",
		type: "text",
	},
	{
		label: "Your Company Details",
		elementName: "companyDetails",
		type: "text",
	},
	{
		label: "Phone Number 1",
		elementName: "phoneNumber1",
		type: "text",
	},
	{
		label: "Phone Number 2",
		elementName: "phoneNumber2",
		type: "text",
	},
];

export const applicationSettings = [
	{
		label: "Display Language",
		elementName: "language",
		type: "pulldown",
		defaultValue: "en",
		hideResetButton: true,
		pullDownMenu: [
			{
				key: "en",
				value: "English",
			},
			{
				key: "fr",
				value: "French",
			},
		],
	},
];

export const notificationsSettings = [
	{
		label: "Declaration Notifications",
		elementName: "declaration",
		type: "pulldown",
		hideValueFromOptionLabel: true,
		hideResetButton: true,
		pullDownMenu: [
			{
				key: "disabled",
				value: "Disabled",
			},
			{
				key: "email",
				value: "Email",
			},
			{
				key: "push",
				value: "In-App Notifications",
			},
			{
				key: "both",
				value: "Email and In-App Notifications",
			},
		],
	},
];
