import { useContext } from "react";
import ThemeContext from "../../../../context/theme/ThemeContext";
import ReactMarkdown from "react-markdown";

const Message = ({ message }) => {
	const { withTheme } = useContext(ThemeContext);

	return (
		<div className={withTheme(`chat-box-message chat-box-${message.role}-message`)}>
			<ReactMarkdown children={message.content} />
		</div>
	);
};

export default Message;
