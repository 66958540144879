import React, { useContext, useState, useEffect, useMemo } from "react";
import elements from "../../../../constants/validation";
import DeclarationsContext from "../../../../context/declarations/DeclarationsContext";
import { createContact, updateContact } from "../../../../api/contacts";
import NotificationToast from "../../../common/NotificationToast";
import ThemeContext from "../../../../context/theme/ThemeContext";
import SectionActions from "./components/SectionActions";
import CustomSectionSearchButton from "../CustomSections/CustomSectionSearchButton";
import { SEARCH_TYPES, SEARCH_TYPE_ELEMENTS } from "../../../../constants/GlobalConstants";
import ValidationInput from "../../../common/InputsSection/ValidationInput";
import WorkspacesContext from "../../../../context/workspaces/WorkspacesContext";
import { useTranslation } from "react-i18next";
import { validateElements } from "./utils";

const ContactForm = (props) => {
	const {
		setItemElementValueInSection,
		setElementValueInSection,
		setHasUnsavedChanges,
		isDeclarationReadOnly,
		state,
	} = useContext(DeclarationsContext);
	const { selectedWorkspaceId } = useContext(WorkspacesContext);
	const { declaration } = state;
	const [isSaveConfirmToastOpen, setIsSaveConfirmToastOpen] = useState(false);
	const [inputValues, setInputValues] = useState({});
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const { formName, formRole, itemIndex } = props;
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	const readOnly = useMemo(() => isDeclarationReadOnly(), [state.declaration]);

	const contactElements = useMemo(() => {
		if (formRole === "representative") {
			return [
				...SEARCH_TYPE_ELEMENTS.CONTACTS,
				{ name: "representativeStatusCode", type: "pulldown" },
			];
		} else {
			return [...SEARCH_TYPE_ELEMENTS.CONTACTS];
		}
	}, [formRole]);

	const handleUpdateExistingClick = async () => {
		if (!inputValues._id) {
			return;
		}
		setIsUpdateLoading(true);

		const { _id, ...data } = inputValues;

		await updateContact(_id, data);
		setIsUpdateLoading(false);
	};

	const handleSaveClick = async () => {
		const { _id, ...data } = inputValues;

		const errors = validateElements(data, SEARCH_TYPE_ELEMENTS.CONTACTS);

		setErrors(errors);

		if (Object.keys(errors).length) {
			return;
		}

		setIsSaveLoading(true);

		const contact = await createContact({ ...data, workspace: selectedWorkspaceId });

		setInputValues({
			...contact.data,
			_id: contact._id,
		});

		setIsSaveConfirmToastOpen(true);
		setIsSaveLoading(false);
	};

	const handleClearClick = () => {
		let updatedState = {};

		contactElements.forEach((element) => {
			updatedState[element.name] = "";
			if (typeof itemIndex === "number") {
				setItemElementValueInSection(itemIndex, formRole, element.name, "");
			} else {
				setElementValueInSection(formRole, element.name, "");
			}
		});

		setInputValues(updatedState);
	};

	const handleChange = (name, value) => {
		const updatedState = {
			...inputValues,
			[name]: value,
		};

		setHasUnsavedChanges(true);

		if (typeof itemIndex === "number") {
			setItemElementValueInSection(itemIndex, formRole, name, value);
		} else {
			setElementValueInSection(formRole, name, value);
		}

		setInputValues(updatedState);

		if (errors[name]) {
			delete errors[name];
			setErrors({ ...errors });
		}
	};

	const handleSearchSelection = (data) => {
		setIsSearchModalOpen(false);
		if (data) {
			Object.keys(data).forEach((key) => {
				if (typeof itemIndex === "number") {
					setItemElementValueInSection(itemIndex, formRole, key, data[key]);
				} else {
					setElementValueInSection(formRole, key, data[key]);
				}
			});
		}
	};

	useEffect(() => {
		if (typeof itemIndex === "number") {
			setInputValues(declaration?.data.declarationItems?.[itemIndex]?.[formRole] || {});
		} else {
			setInputValues(declaration.data[formRole] || {});
		}
	}, [state.declaration, itemIndex]);

	return (
		<div className={`${withTheme("peach")} ${withTheme("inputs-section")}`}>
			<NotificationToast
				isOpen={isSaveConfirmToastOpen}
				onClose={() => setIsSaveConfirmToastOpen(false)}
				variant="success"
				text={t("contactSaveSuccess")}
			/>

			<div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
				<div className={withTheme("inputs-section-title")}>
					{t("contactInfo")}: {formName}
				</div>

				{!readOnly && (
					<CustomSectionSearchButton
						setIsSearchModalOpen={setIsSearchModalOpen}
						isSearchModalOpen={isSearchModalOpen}
						sectionElements={contactElements}
						onAddClick={handleSearchSelection}
						searchType={SEARCH_TYPES.CONTACTS}
					/>
				)}
			</div>

			<div style={{ display: "flex", flexWrap: "wrap", columnGap: 40, rowGap: 20 }}>
				{contactElements.map((element, index) => (
					<Element
						readOnly={readOnly}
						element={element}
						error={t(errors[element.name])}
						inputValues={inputValues}
						onChange={handleChange}
						key={index}
					/>
				))}
			</div>

			{!readOnly && (
				<SectionActions
					isUpdateLoading={isUpdateLoading}
					isSaveLoading={isSaveLoading}
					onUpdateExistingClick={handleUpdateExistingClick}
					onClearClick={handleClearClick}
					onSaveClick={handleSaveClick}
					inputValues={inputValues}
				/>
			)}
		</div>
	);
};

const Element = ({ element, error, readOnly, inputValues, onChange }) => {
	const validationElement = useMemo(
		() => elements.find((tmpElement) => tmpElement.elementName === element.name),
		[element]
	);

	return (
		<div style={{ width: "calc(50% - 40px)", flexGrow: 1 }}>
			<ValidationInput
				onChange={onChange}
				groupingElement={element}
				value={inputValues[element.name]}
				element={validationElement}
				inputStyle={{ width: "100%" }}
				readOnly={readOnly}
				error={error}
			/>
		</div>
	);
};

export default ContactForm;
