import { useContext } from "react";
import Card from "../../common/Card";
import ThemeContext from "../../../context/theme/ThemeContext";
import { useTranslation } from "react-i18next";
import SubscriptionUsageTrackers from "../../../pages/Settings/Tabs/Subscription/SubscriptionUsageTrackers";

const SubscriptionUsageCard = () => {
	const { t } = useTranslation();
	const { withTheme } = useContext(ThemeContext);

	return (
		<Card style={{ height: 264, width: "50%" }}>
			<div className={withTheme("dashboard-card-label")}>{t("subscriptionUsage")}</div>

			<div style={{ paddingLeft: 16, paddingRight: 16 }}>
				<SubscriptionUsageTrackers />
			</div>
		</Card>
	);
};

export default SubscriptionUsageCard;
