import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import ThemeContext from "../../../context/theme/ThemeContext";
import DeclarationsContext from "../../../context/declarations/DeclarationsContext";
import { validateElementValue } from "./utils";
import { ITEM_QUERY_PARAM, COMMODITY_CODES_URL } from "../../../constants/GlobalConstants";
import moment from "moment";
import ElementLabel from "../ElementLabel";
import { getQueryParam } from "../../../router/utils";
import { useTranslation } from "react-i18next";
import ErrorText from "../ErrorText";

const CustomTextInput = (props) => {
	const { withTheme } = useContext(ThemeContext);
	const declarationContext = useContext(DeclarationsContext);
	const [inputValue, setInputValue] = useState("");
	const [validationWarning, setValidationWarning] = useState("");
	const { t } = useTranslation();
	const { element, value, onChange, inputStyle, dateInput, error } = props;

	const { elementName, placeholder, rows } = element || {};

	const { state, isElementReadOnly } = declarationContext;

	const isDeclarationReadOnly = useMemo(() => {
		if (element?.readOnly || isElementReadOnly(elementName)) {
			return true;
		} else {
			return false;
		}
	}, [element]);

	const isMandatory = useMemo(
		() => state?.mandatoryElements?.find((element) => element.elementName === elementName),
		[state.mandatoryElements, elementName]
	);
	const isDisabled = useMemo(
		() =>
			props.diasbled ||
			state?.disabledElements?.find(
				(element) =>
					element.elementName === elementName &&
					element.itemIndex === parseInt(getQueryParam(ITEM_QUERY_PARAM))
			),
		[state.disabledElements, elementName]
	);

	const debouncedUpdate = useCallback(
		debounce((value) => {
			onChange?.(elementName, value);
		}, 300),
		[elementName, onChange]
	);

	/**
	 * Changing the selected input value after debounce it for 0.3s and set it to the state
	 */
	const handleInputChange = (e) => {
		// Convert the date value to the HMRC format (YYYYMMDDHHMMSS)
		const formattedValue = dateInput
			? moment(e.target.value).format("YYYYMMDD") + "000000+01"
			: e.target.value;

		if (validateElementValue(element, e.target.value, setValidationWarning)) {
			if (onChange != null) {
				debouncedUpdate(formattedValue);
				setInputValue(e.target.value);
			}
		}
	};

	// Opens the commodty code web page in a new window and positions at the center of the page
	const handleSelectCodeClick = () => {
		window.open(
			COMMODITY_CODES_URL,
			"_blank",
			`location=yes,height=600,width=700,scrollbars=yes,top=${window.screen.height - 900},left=${
				window.screen.width - 1200
			}`
		);
	};

	useEffect(() => {
		if (dateInput) {
			if (value) {
				// Convert the date from the HMRC format (YYYYMMDDHHMMSS) to just (YYYMMDD) for JavaScript to understand
				setInputValue(moment(value.replace("000000+01", ""))?.format("YYYY-MM-DD"));
			} else {
				setInputValue(undefined);
			}
		} else {
			setInputValue(value);
		}

		if (!value) {
			setValidationWarning("");
		}
	}, [value]);

	// Clear the input value if it's been disabled
	useEffect(() => {
		if (isDisabled && value) {
			declarationContext.setElementValue(elementName, "");
			setInputValue("");
		}
	}, [isDisabled]);

	const inputProps = {
		disabled: isDisabled || isDeclarationReadOnly,
		type: dateInput ? "date" : "text",
		readOnly: isDeclarationReadOnly,
		value: inputValue || "",
		id: elementName,
		placeholder: placeholder || props.element.label,
		style: {
			...inputStyle,
			background: isDeclarationReadOnly ? "#f0f0f0" : "",
			border: isMandatory || error ? "1px solid #dc3545" : "",
		},
		name: elementName,
		className: `${withTheme(elementName)} ${withTheme("input")}`,
		onChange: handleInputChange,
	};

	return (
		<div id={elementName} className="mb-2 mr-2">
			<ElementLabel element={props.element} isMandatory={isMandatory} />

			{rows ? <textarea rows={rows} {...inputProps} /> : <input {...inputProps} />}

			{elementName === "commodityCodeId" && (
				<div
					style={{ cursor: "pointer", fontSize: 12, marginTop: 4, textAlign: "left" }}
					onClick={handleSelectCodeClick}
					className="link-primary"
				>
					{t("selectCommodityCode")}
				</div>
			)}

			<ErrorText
				style={{
					minHeight: error || validationWarning ? 20 : 0,
					maxHeight: error || validationWarning ? 20 : 0,
				}}
			>
				{error || validationWarning}
			</ErrorText>
		</div>
	);
};

export default CustomTextInput;
