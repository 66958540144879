import { useContext, useEffect, useState } from "react";
import Button from "../../../components/common/Button";
import UserContext from "../../../context/user/UserContext";
import { PiFloppyDisk } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { userSettingElements } from "../../../constants/settings";
import ValidationInput from "../../../components/common/InputsSection/ValidationInput";
import NotificationToast from "../../../components/common/NotificationToast";

const UserSettingsTab = () => {
	const { t } = useTranslation();

	const [inputValues, setInputValues] = useState({});
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const { updateSettings, userSettings } = useContext(UserContext);
	const [toast, setToast] = useState({ text: "", variant: "" });

	const handleChange = (name, value) => {
		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleResetClick = () => {
		setInputValues({ ...userSettings });
	};

	const handleSaveClick = async () => {
		setIsSaveLoading(true);

		try {
			await updateSettings({
				...inputValues,
			});

			setToast({ text: t("settingsSaveSuccess"), variant: "success" });
		} catch (e) {
			console.log(e.response.data);

			setToast({ text: t("somethingWentWrong"), variant: "error" });
		}

		setIsSaveLoading(false);
	};

	useEffect(() => {
		if (userSettings) {
			setInputValues({ ...userSettings.settings });
		} else {
			setInputValues({});
		}
	}, [userSettings]);

	return (
		<div>
			<NotificationToast
				isOpen={Boolean(toast.text)}
				onClose={() => setToast({})}
				text={toast.text}
				variant={toast.variant}
			/>

			<div style={{ width: "calc(50% - 20px)" }}>
				<ValidationInput
					element={{
						elementName: "name",
						label: "Full Name",
						type: "text",
						readOnly: true,
					}}
					onChange={handleChange}
					value={userSettings?.name}
				/>
			</div>

			<div style={{ display: "flex", flexWrap: "wrap", rowGap: 16, columnGap: 32 }}>
				{userSettingElements.map((element) => (
					<div style={{ width: "calc(50% - 20px)" }}>
						<ValidationInput
							onChange={handleChange}
							value={inputValues[element.elementName] || element.defaultValue}
							element={element}
						/>
					</div>
				))}
			</div>

			<div
				style={{
					width: "fit-content",
					marginLeft: "auto",
					display: "flex",
					alignItems: "center",
					gap: 12,
					marginTop: 52,
				}}
			>
				<Button type="secondary" onClick={handleResetClick} disabled={isSaveLoading}>
					{t("reset")}
				</Button>
				<Button isLoading={isSaveLoading} onClick={handleSaveClick} LeftIcon={PiFloppyDisk}>
					{t("saveChanges")}
				</Button>
			</div>
		</div>
	);
};

export default UserSettingsTab;
