import { axiosInstance } from "../index";
import config from "../config";
import { getHeaders } from "./helpers";

const { apiUrl } = config;

export const getDeclarationById = async (declarationId) => {
	const result = await axiosInstance.get(`${apiUrl}/declarations/${declarationId}`, {
		headers: getHeaders(),
	});

	return result.data.declaration;
};

export const getDeclarationHmrcDetails = async (declarationId) => {
	const result = await axiosInstance.get(`${apiUrl}/declarations/${declarationId}/hmrc-details`, {
		headers: getHeaders(),
	});

	return result.data;
};

export const getDeclarationReport = async (declarationId) => {
	const result = await axiosInstance.get(`${apiUrl}/declarations/${declarationId}/report`, {
		headers: getHeaders(),
	});

	return result.data;
};

export const getDeclarationLogs = async (declarationId) => {
	const result = await axiosInstance.get(`${apiUrl}/declarations/${declarationId}/logs`, {
		headers: getHeaders(),
	});

	return result.data.logs;
};

export const fetchDeclarations = async (workspaceId, params) => {
	const result = await axiosInstance.get(`${apiUrl}/declarations`, {
		params: { workspaceId, count: params?.count, submitted: params?.submitted },
		headers: getHeaders(),
	});

	return result.data;
};

export const createDeclaration = async (payload) => {
	const result = await axiosInstance.post(`${apiUrl}/declarations`, payload, { headers: getHeaders() });

	return result.data.declaration;
};

export const updateDeclaration = async (declarationId, payload) => {
	let params = {};

	const result = await axiosInstance.put(`${apiUrl}/declarations/${declarationId}`, payload, {
		headers: getHeaders(),
		params,
	});

	return result.data.declaration;
};

export const deleteDeclaration = async (_id) => {
	const result = await axiosInstance.delete(`${apiUrl}/declarations/${_id}`, {
		headers: getHeaders(),
	});

	return result.data;
};

export const submitDeclaration = async (declaration) => {
	const result = await axiosInstance.post(
		`${apiUrl}/declarations/${declaration._id}/submit`,
		{},
		{
			headers: getHeaders(),
		}
	);

	console.log(result);

	return result.data;
};

export const cancelDeclaration = async (declarationId, cancelReason) => {
	const result = await axiosInstance.post(
		`${apiUrl}/declarations/${declarationId}/cancel`,
		{ cancelReason },
		{ headers: getHeaders() }
	);

	return result.data;
};
