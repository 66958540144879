import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ValidationInput from "../../../../components/common/InputsSection/ValidationInput";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../../../context/theme/ThemeContext";
import { notificationsSettings } from "../../../../constants/settings";
import Button from "../../../../components/common/Button";

const NotificationSettings = ({ inputValues, setInputValues }) => {
	const { withTheme } = useContext(ThemeContext);
	const { t } = useTranslation();

	const [isPermGranted, setIsPermGranted] = useState(Notification.permission === "granted");
	const permissionStatus = useRef(null);

	const values = useMemo(() => {
		const { declaration } = inputValues.notifications || {};

		let result = {};

		if (inputValues.notifications) {
			Object.keys(inputValues.notifications).forEach((key) => {
				if (declaration?.email && declaration.push) {
					result[key] = "both";
				} else if (declaration?.email) {
					result[key] = "email";
				} else if (declaration?.push) {
					result[key] = "push";
				} else {
					result[key] = "disabled";
				}
			});
		}

		return result;
	}, [inputValues]);

	const handleRequestPermClick = async () => {
		await Notification.requestPermission();
	};

	const handleNotificationSettingChange = (type, value) => {
		let updates = {};

		if (value === "both") {
			updates = {
				email: true,
				push: true,
			};
		} else if (value === "email") {
			updates = {
				email: true,
				push: false,
			};
		} else if (value === "push") {
			updates = {
				email: false,
				push: true,
			};
		} else if (value === "disabled") {
			updates = {
				email: false,
				push: false,
			};
		}

		console.log(value);

		setInputValues({
			...inputValues,
			notifications: {
				...inputValues.notifications,
				[type]: updates,
			},
		});
	};

	/** Adds permission event listener to update the UI whenever the permission is updated */
	useEffect(() => {
		const handler = () => {
			setIsPermGranted(Notification.permission === "granted");
		};

		(async () => {
			permissionStatus.current = await navigator.permissions.query({ name: "notifications" });
			permissionStatus.current.addEventListener("change", handler);
		})();

		return () => {
			permissionStatus.current?.removeEventListener("change", handler);
		};
	}, []);

	return (
		<>
			<div style={{ marginTop: 32 }} className={withTheme("settings-section-label")}>
				{t("notifications")}
			</div>
			<div style={{ width: "calc(50% - 20px)" }}>
				{notificationsSettings.map((element, index) => (
					<ValidationInput
						key={index}
						onChange={(_name, value) =>
							handleNotificationSettingChange(element.elementName, value)
						}
						value={values.declaration || "disabled"}
						element={element}
					/>
				))}
			</div>

			{!isPermGranted && (
				<Button mt={4} type="text" onClick={handleRequestPermClick}>
					{t("enableBrowserNotifsButton")}
				</Button>
			)}
		</>
	);
};

export default NotificationSettings;
