const OptionLabel = (props) => {
	const isRequired = props.requiredKeys.includes(props.value);

	return (
		<div
			style={{
				color: isRequired ? "#dc3545" : "",
				overflow: "hidden",
			}}
		>
			<span
				style={{
					fontWeight: isRequired ? 600 : 400,
				}}
			>
				{props.label}
			</span>
			{!props.hideValueFromOptionLabel && (
				<span style={{ fontWeight: 600 }}>
					{props.value !== "DEFAULT" && props.label ? `, ${props.value}` : ""}
				</span>
			)}
		</div>
	);
};

export default OptionLabel;
